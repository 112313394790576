@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;700&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Rajdhani', sans-serif;
    background: black;
    color: white;
    display: block;
    background-image: url(./assets/bg.webp);
    background-repeat: repeat-y;
    background-position: center 0;
    background-size: cover;
}

.App {
    max-width: 100vw;
    overflow: hidden;
}