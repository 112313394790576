.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.3rem auto;
    padding: 0 2rem;
    max-width: 1500px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.navBarWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.navWrap {
    display: block;
    background: linear-gradient(#1a0e22 20%, #1a0e2288 80%, #1a0e2200);
    height: 8rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    opacity: 1;
}

.nav img {
    width: 20rem;
}

.btns {
    cursor: pointer;
    padding: 1rem 0;
}

.btns>div {
    background: white;
    height: 0.4rem;
    width: 2.8rem;
    border-radius: 0.1rem;
    display: block;
    transition: .3s;
}


.btns>div:nth-of-type(1) {
    margin-bottom: 0.6rem;
}

.btns>div:nth-of-type(2) {
    margin-bottom: 0.6rem;
    transform: translateX(-1.2rem);
}

.btns:hover div:nth-of-type(1) {
    transform: translateX(-1.2rem);
}

.btns:hover div:nth-of-type(2) {
    transform: translateX(0);
}

.overlay {
    min-height: -webkit-fill-available;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    background: rgba(26, 15, 34, .9);
    font-weight: bold;
    font-size: 4rem;
    z-index: 10;
}

@keyframes overlayItem {
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.overlay>div {
    cursor: pointer;
    transition: .3s;
    opacity: 0;
    transform: translateX(-10rem);
    animation: overlayItem 1s forwards;
}

.overlay>div:nth-of-type(2) {
    animation-delay: .1s;
}

.overlay>div:nth-of-type(3) {
    animation-delay: .2s;
}

.overlay>div:nth-of-type(4) {
    animation-delay: .3s;
}

.overlay>div:nth-of-type(5) {
    animation-delay: .4s;
}

.overlay>div:last-of-type {
    color: #ED2E44;
    text-decoration: underline;
    text-underline-offset: 1rem;
}

.overlay>div:hover {
    color: #ed2e44;
}

@media (max-width: 850px) {
    .nav {
        margin: 0;
        padding: 1rem;
        padding-bottom: 0.7rem;
        /* background: linear-gradient(#1a0e22 20%, #1a0e2288 80%, #1a0e2200); */
    }

    .nav img {
        width: 50vw;
    }

    .btns {
        transform: translateY(1vw);
    }

    .btns>div {
        height: 0.3rem;
        width: 2.3rem;
    }

    .overlay {
        font-size: 3rem;
    }
}