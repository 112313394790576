body {
    background-position: center -30rem;
}

.eventsPage {
    margin-top: 7rem;
}

.header {
    height: 23rem;
    overflow: hidden;
    padding-bottom: 5rem;
    position: relative;
}

.headerSection {
    margin-bottom: -4rem
}

@keyframes hueRotate {
    0% {
        filter: hue-rotate(10deg);
    }

    50% {
        filter: hue-rotate(-10deg);
    }

    100% {
        filter: hue-rotate(10deg);
    }
}

.bgimgheader {
    height: 100%;
    width: 100%;
    object-fit: cover;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.5);
    animation: hueRotate forwards 3s infinite;
}

.eventText {
    margin: 0 auto;
    display: block;
    margin-top: -16.1rem;
    width: 50rem;
    position: relative;
}


@media (max-width: 850px) {
    .eventsPage {
        margin-top: calc(13vw + 2.3rem);
    }

    .header {
        height: 15rem;
    }

    .eventText {
        width: 88vw;
        margin-top: calc(-5.2rem - 18.9vw);
    }
}


.contact {
    margin: 0 auto;
    display: flex;
    margin-top: 1rem;
    padding-top: 9rem;
    flex-direction: column;
    align-items: center;
    width: 615px;
    font-size: 1.05rem;
}

.contact img {
    width: 615px;
    margin-bottom: -1.8rem;
}

.contact textarea {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 10px;
    padding: 24px 33px;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: #c3bace;
    background-color: #2b1d36;
    border: none;
    border-radius: 0;
    outline: 0;
    cursor: text;
    filter: drop-shadow(0 3px 7px rgba(14, 8, 18, .44));
    resize: vertical;
}

.contact input {
    position: relative;
    width: 100%;
    margin: 0 0 10px;
    padding: 24px 33px;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: calc(63px - (48px - 2px));
    color: #c3bace;
    background-color: #2b1d36;
    border: none;
    border-radius: 0;
    outline: 0;
    cursor: text;
    box-sizing: border-box;
    filter: drop-shadow(0 3px 7px rgba(14, 8, 18, .44));
}

.contact textarea::placeholder,
.contact input::placeholder {
    color: #c3bace;
}

.textArea {
    width: 100%;
    height: fit-content;
    position: relative;
}

.textArea::after {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    height: 0.25rem;
    transform-origin: left;
    transform: scaleX(0);
    background-color: #f3223a;
    transition: .5s cubic-bezier(.8, .18, .25, .69);
    pointer-events: none;
}

.textArea:hover::after {
    transform: scaleX(1);
}

.nameemail {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 0.1rem;
    margin-top: -0.3rem;
    width: 100%;
}

.contact button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.contactAlert {
    opacity: 0.75;
    border-left: 0.4rem solid #00d26a;
    padding-left: 0.7rem;
    margin-bottom: 0.5rem;
    align-self: flex-start;
}


.sponsorDesc {
    margin-bottom: 1.5rem;
    text-align: justify;
}

.sponsorDesc span:nth-of-type(1),
.sponsorDesc span:nth-of-type(2) {
    opacity: 0.6;
}

@media (max-width: 650px) {
    .contact {
        width: 90vw;
        margin-top: 7rem;
        padding-top: 1rem;
        margin-bottom: -2rem;
    }

    .nameemail {
        flex-direction: column;
        gap: 0.1rem;
        margin-top: -0.35rem;
    }
}